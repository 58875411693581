import {useAtom} from "jotai";
import {Button, Grid} from '@mui/material'
import {
  areaFrom, areaTo,
  isLabelingDataSelected,
  labeledData,
  selectedLabelingData, targetLabelingDataInfo
} from "../../jotai/atom";
import {LabeledSensorData, SensorData} from "../../types/types";
import {labelDefinitions} from "../../constants/definitions";

export const LabelingController = () => {
  const [target] = useAtom(targetLabelingDataInfo)
  const [labeledSensorData, setLabeledSensorData] = useAtom(labeledData)
  const [isDataSelected] = useAtom(isLabelingDataSelected)
  const [selectedData, setSelectedData] = useAtom(selectedLabelingData)
  const [, setRefAreaFrom] = useAtom(areaFrom)
  const [, setRefAreaTo] = useAtom(areaTo)

  const disableAll = target == null

  const labelOneData = (data: SensorData, label: string) => {
    return {
      label,
      ...data
    } as LabeledSensorData
  }

  const labelSelectedData = (label: string) => {
    const selectedDataLabeled = selectedData.map(data => {
      return labelOneData(data, label)
    })
    const newLabeledData = [...labeledSensorData, selectedDataLabeled]
    console.log(newLabeledData)
    setLabeledSensorData(newLabeledData)
    setSelectedData([])
    setRefAreaFrom('')
    setRefAreaTo('')
  }

  const labelButtons = labelDefinitions.map((labelDefinition) => {
    return (
      <Button
        sx={{margin: 2}}
        variant="outlined"
        key={labelDefinition.label}
        onClick={() => {labelSelectedData(labelDefinition.label)}}
        disabled={!isDataSelected || disableAll}
      >{labelDefinition.label_name}</Button>
    )
  })

  const allLabelResetButton = labeledSensorData.length > 0 ? (
    <Button
      sx={{margin: 2}}
      variant="contained"
      key="reset-label"
      onClick={() => {setLabeledSensorData([])}}
      color="warning"
      disabled={disableAll}
    >全てのラベルをリセット</Button>
  ) : <></>

  return (
    <Grid columns={2}>
      <Grid>{labelButtons}</Grid>
      {allLabelResetButton}
    </Grid>
  )
}

export default LabelingController;
import React, {Suspense} from "react";

import {
  Drawer,
  Toolbar,
  List, ListItem,
  Typography
} from '@mui/material';

import {useAtom} from "jotai";

import {targetLabelingDataInfo} from "../../jotai/atom";
import LabelingTargetListDropdown from "./LabelingTargetListDropdown";
import Loader from "../ui/Loader";
import SensorMaster from "./SensorMaster";

const drawerWidth = 400;

const Sidebar = () => {
  const [targetInfo] = useAtom(targetLabelingDataInfo)

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar sx={{
        marginTop: 3
      }}/>
      <Suspense fallback={<Loader />}>
        <LabelingTargetListDropdown />
      </Suspense>
      <Typography variant="caption" align="center" paddingTop={1}>
        同じデータに対して複数人で同時にラベリング作業を行うと、<br/>
        予期せぬエラーが起こる可能性があります。
      </Typography>
      {
        targetInfo ? (
          <List>
            <ListItem>シリアル番号: {targetInfo.serial_no}</ListItem>
            <ListItem>データの期間: {targetInfo.period}</ListItem>
          </List>
         ) : null
      }
      <Suspense fallback={<Loader />}>
        <SensorMaster />
      </Suspense>
    </Drawer>
  )
}

export default Sidebar
import {Suspense, useTransition} from "react";

import {Grid, Toolbar, Backdrop} from '@mui/material'
import {SensorDataChart} from "./SensorDataChart";
import Loader from "../ui/Loader";
import LabelingController from "./LabelingController";
import SavingLabeledDataUI from "./SavingLabeledDataUI";

function Content() {
  const [pending, startTransition] = useTransition()

  return (
    <Grid container>
      <Grid lg={16} sm={16} item={true}>
        <Toolbar sx={{
          marginTop: 3
        }}/>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={pending}
        >
          <Loader />
        </Backdrop>
        <Suspense fallback={<Loader />}>
          <SensorDataChart />
          <LabelingController />
          <SavingLabeledDataUI startTransition={startTransition} />
        </Suspense>
      </Grid>
    </Grid>
  )
}

export default Content
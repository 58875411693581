import {useAtom} from "jotai";
import {
  Button,
  Fab,
  Dialog, DialogTitle, DialogContent, DialogActions, Typography
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import {
  allSensorDataForSave,
  isSaveModalOpen, isSaving, isSkipModalOpen, isSkipping,
  setNextTargetLabelingDataInfo, targetLabelingDataInfo
} from "../../jotai/atom";
import {callPostApi} from "../../api/api";

export const SavingLabeledDataUI = (props: { startTransition: (arg0: () => void) => void; }) => {
  const [target] = useAtom(targetLabelingDataInfo)
  const [allDataForSave] = useAtom(allSensorDataForSave)
  const [, setNextTarget] = useAtom(setNextTargetLabelingDataInfo)

  const disableAll = target == null

  const [openSaveModal, setOpenSaveModal] = useAtom(isSaveModalOpen)
  const doOpenSaveModal = () => {
    setOpenSaveModal(true);
  };
  const doCloseSaveModal = () => {
    setOpenSaveModal(false);
  };

  const [openSkipModal, setOpenSkipModal] = useAtom(isSkipModalOpen)
  const doOpenSkipModal = () => {
    setOpenSkipModal(true);
  };
  const doCloseSkipModal = () => {
    setOpenSkipModal(false);
  };

  // const [saving, setSaving] = useState<boolean>(false)
  const [saving, setSaving] = useAtom(isSaving)
  const save = async () => {
    setSaving(true)
    await callPostApi('/save_labeled_sensor_data', {...allDataForSave})
    setSaving(false)
  }

  // const [skipping, setSkipping] = useState<boolean>(false)
  const [skipping, setSkipping] = useAtom(isSkipping)
  const skip = async () => {
    setSkipping(true)
    await callPostApi('/skip_labeling', {...target})
    setSkipping(false)
  }

  const setNext = () => {
    props.startTransition(() => {
      setNextTarget();
    });
  }

  return (
    <>
      <Fab
        sx={{position: 'absolute', bottom: 50, right: 50}}
        variant="extended"
        color="primary"
        aria-label="Save"
        onClick={doOpenSaveModal}
        disabled={disableAll}
      >
        <SaveAltIcon />
        ラベリングを保存
      </Fab>
      <Fab
        sx={{position: 'absolute', bottom: 50, right: 250}}
        variant="extended"
        color="primary"
        aria-label="Skip"
        onClick={doOpenSkipModal}
        disabled={disableAll}
      >
        <SkipNextIcon />
        このデータへのラベリングをスキップ
      </Fab>
      <Dialog
        open={openSaveModal}
        onClose={doCloseSaveModal}
        aria-labelledby="save-dialog-title"
      >
        <DialogTitle id="save-dialog-title">
          {"ラベリングの保存"}
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>センサ: {target?.serial_no}, 期間: {target?.period}</Typography>
          <Typography gutterBottom>ラベリングした内容を保存しますか？</Typography>
          <Typography gutterBottom>※注: ラベリングしていない箇所は「正常」とみなされます。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={doCloseSaveModal}>キャンセル</Button>
          <LoadingButton
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveAltIcon />}
            variant="contained"
            onClick={async () => {
              await save()
              doCloseSaveModal()
              setNext()
            }}
          >
            保存する
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSkipModal}
        onClose={doCloseSkipModal}
        aria-labelledby="skip-dialog-title"
      >
        <DialogTitle id="skip-dialog-title">
          {"ラベリングのスキップ"}
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>センサ: {target?.serial_no}, 期間: {target?.period}</Typography>
          <Typography gutterBottom>このデータへのラベリングをスキップしますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={doCloseSkipModal}>キャンセル</Button>
          <LoadingButton
            loading={skipping}
            loadingPosition="start"
            startIcon={<SkipNextIcon />}
            variant="contained"
            onClick={async () => {
              await skip()
              doCloseSkipModal()
              setNext()
            }}
          >
            スキップ
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SavingLabeledDataUI;
import { API, Auth } from 'aws-amplify';

export const callPostApi = async (path: string, body?: object) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const init = {
    headers: {
      Authorization: token,
    },
    body
  };
  return API.post(
    'DefaultAPI', path, init
  )
}
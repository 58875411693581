import {atom} from "jotai";

import {LabeledSensorData,
  LabeledSensorDataAndMaster, LabelingDataInfo, SensorData, SensorDataAndMaster} from "../types/types"
import {callPostApi} from "../api/api";

export const targetLabelingDataInfo = atom<LabelingDataInfo|null>(null)

export const allLabelingTargetList = atom<LabelingDataInfo[]>([])
export const fetchAllLabelingTargetListByApi = atom(
  null,
  async (_, set) => {
    set(allLabelingTargetList, await callPostApi('/get_labeling_target_list'))
  }
)

export const targetSensorDataAndMaster = atom(
  async (get) => {
    const target = get(targetLabelingDataInfo)
    if (target == null) {
      return undefined
    }
    const response = await callPostApi('/get_labeling_target_data', {...target})
    return response as SensorDataAndMaster
  },
)

export const targetSensorMaster = atom(
  async (get) => {
    const data = await get(targetSensorDataAndMaster)
    return data ? data.master_data: undefined
  }
)

export const targetSensorData = atom(
  async (get) => {
    const allData = await get(targetSensorDataAndMaster)
    return allData ? allData.labeling_target_data : []
  }
)

export const selectedLabelingData = atom<SensorData[]>([])
export const isLabelingDataSelected = atom(
  (get) => {
    return get(selectedLabelingData).length !== 0;
  }
)

export const labeledData = atom<LabeledSensorData[][]>([])
export const notLabeledData = atom(
  (get) => {
    const allSensorData = get(targetSensorData)
    const labeledSensorData = get(labeledData)
    if (labeledSensorData.length === 0) {
      return allSensorData
    }
    // allSensorDataのうち、labeledSensorDataにmeasured_atが無いもの
    return allSensorData.filter((targetData) => {
        return (labeledSensorData.every((rangeLabeledData) => {
          return rangeLabeledData.findIndex((oneLabeledData) => {
            return oneLabeledData.measured_at === targetData.measured_at
          }) === -1
        }))
    })
  }
)

export const allSensorDataForPlot = atom(
  (get) => {
    const labeledSensorData = get(labeledData)
    const notLabeledSensorData = get(notLabeledData)
    const allLabeledData = labeledSensorData.flat().map((oneLabeledData) => {
      return {
        ...oneLabeledData,
        labeled_value: oneLabeledData.measure_value,
        not_labeled_value: undefined,
      }
    })
    const allNotLabeledData = notLabeledSensorData.map((oneData) => {
      return {
        ...oneData,
        labeled_value: undefined,
        not_labeled_value: oneData.measure_value
      }
    })
    return [...allNotLabeledData, ...allLabeledData].sort((a, b) => {
      return a.measured_at < b.measured_at ? -1 : a.measured_at > b.measured_at ? 1 : 0
    })
  }
)

export const areaFrom = atom('')
export const areaTo = atom('')

export const areaLeft = atom(
  (get) => {
    const from = get(areaFrom)
    const to = get(areaTo)
    return parseInt(from) < parseInt(to) ? from : to
  }
)
export const areaRight = atom(
  (get) => {
    const from = get(areaFrom)
    const to = get(areaTo)
    return parseInt(from) < parseInt(to) ? to : from
  }
)

export const isSelectedAreaAndLabeledAreaOverlapping = atom(
  (get) => {
    const labeledSensorData = get(labeledData)
    const left = get(areaLeft)
    const right = get(areaRight)
    return labeledSensorData.some((rangeLabeledData) => {
      return rangeLabeledData.some((oneLabeledData) => {
        return parseInt(left) === oneLabeledData.measured_at || parseInt(right) === oneLabeledData.measured_at
      }) || (
        parseInt(left) < rangeLabeledData[0].measured_at &&
        parseInt(right) > rangeLabeledData[rangeLabeledData.length - 1].measured_at
      )
    })
  }
)

export const allSensorDataForSave = atom(
  (get) => {
    const serial = get(targetLabelingDataInfo)?.serial_no
    const period = get(targetLabelingDataInfo)?.period
    const master = get(targetSensorMaster)
    const labeledSensorData = get(labeledData)
    const notLabeledSensorData = get(notLabeledData)
    const allLabeledData = labeledSensorData.flat()
    const allNotLabeledData = notLabeledSensorData.map((oneData) => {
      return {
        ...oneData,
        label: 'ok'
      } as LabeledSensorData
    })
    const dataForSave = [...allNotLabeledData, ...allLabeledData].sort((a, b) => {
      return a.measured_at < b.measured_at ? -1 : a.measured_at > b.measured_at ? 1 : 0
    })
    return {
      serial_no: serial,
      period,
      master_data: master,
      labeled_data: dataForSave
    } as LabeledSensorDataAndMaster
  }
)

export const isSaveModalOpen = atom(false)
export const isSkipModalOpen = atom(false)

export const isSaving = atom(false)
export const isSkipping = atom(false)

export const setNextTargetLabelingDataInfo = atom(
  null,
  (get, set) => {
    const presentTarget = get(targetLabelingDataInfo)
    const presentTargetList = get(allLabelingTargetList)
    console.log(`targetList length = ${presentTargetList.length}`)
    const newTargetList = presentTargetList.filter(t => t !== presentTarget)
    console.log(`new targetList length = ${newTargetList.length}`)
    set(allLabelingTargetList, newTargetList)
    const nextTarget = newTargetList[Math.floor(Math.random() * newTargetList.length)]
    set(targetLabelingDataInfo, nextTarget)
    set(labeledData, [])
    set(selectedLabelingData, [])
    set(areaFrom, '')
    set(areaTo, '')
  }
)
import {LabelingDataInfo} from "../../types/types";
import {allLabelingTargetList, fetchAllLabelingTargetListByApi, targetLabelingDataInfo} from "../../jotai/atom";
import {Autocomplete, TextField} from "@mui/material";
import {useAtom} from "jotai";
import {useCallback, useEffect} from "react";

const LabelingTargetListDropdown = () => {
  const [labelingTargetList] = useAtom(allLabelingTargetList)
  const [labelingDataInfo, setLabelingDataInfo] = useAtom(targetLabelingDataInfo)
  const [, fetchAllLabelingTarget] = useAtom(fetchAllLabelingTargetListByApi)

  const fetch = useCallback(async () => {
    await fetchAllLabelingTarget()
  }, [fetchAllLabelingTarget])

  useEffect(() => {
    (async () => {
      await fetch()
    })();
  }, [fetch])

  return (
    <Autocomplete
      id="target-list"
      sx={{ padding: 1 }}
      onChange={(event: any, newValue: LabelingDataInfo | null) => {
        setLabelingDataInfo(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.serial_no === value.serial_no && option.period === value.period}
      value={labelingDataInfo}
      options={labelingTargetList}
      groupBy={(option) => option.serial_no}
      getOptionLabel={(option) => `${option.serial_no}: 期間 ${option.period}`}
      noOptionsText='読み込み中…'
      renderInput={(params) => (
        <TextField
          {...params}
          label="ラベリング対象データを選択"
        />
      )}
    />
  )
}

export default LabelingTargetListDropdown;
import {
  Brush,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import {Snackbar, Alert, Typography} from '@mui/material';
import {useAtom} from "jotai";
import {
  areaLeft, areaRight, areaFrom, areaTo,
  isLabelingDataSelected,
  selectedLabelingData,
  targetSensorMaster,
  allSensorDataForPlot,
  labeledData, isSelectedAreaAndLabeledAreaOverlapping
} from "../../jotai/atom";
import {labelDefinitions} from "../../constants/definitions";
import React, {useState} from 'react';

export const SensorDataChart = () => {
  const [dataForPlot] = useAtom(allSensorDataForPlot)
  const [labeledSensorData] = useAtom(labeledData)
  const [isDataSelected] = useAtom(isLabelingDataSelected)
  const [sensorMaster] = useAtom(targetSensorMaster)
  const [, setSelectedData] = useAtom(selectedLabelingData)
  const [isSelectedAndLabeledOverlapping] = useAtom(isSelectedAreaAndLabeledAreaOverlapping)
  const [refAreaFrom, setRefAreaFrom] = useAtom(areaFrom)
  const [refAreaTo, setRefAreaTo] = useAtom(areaTo)
  const [refAreaLeft] = useAtom(areaLeft)
  const [refAreaRight] = useAtom(areaRight)

  const [openOverlappingAlert, setOpenOverlappingAlert] = useState<boolean>(false)
  const handleCloseOverlappingAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenOverlappingAlert(false);
  };

  if (sensorMaster == null) {
    return <Typography sx={{ margin: 2 }} gutterBottom color='gray'>データが選択されていません</Typography>
  }

  const formatEpochToDatetimeString = (epoch: number) => {
    return new Date(epoch * 1000).toLocaleString("ja-JP");
  }
  const formatEpochToDateString = (epoch: number) => {
    return new Date(epoch * 1000).toLocaleDateString("ja-JP");
  }

  const selectData = () => {
    const leftLabel = parseInt(refAreaLeft ? refAreaLeft : '')
    const rightLabel = parseInt(refAreaRight ? refAreaRight : '')
    const leftIndex = dataForPlot.findIndex((oneData) => oneData.measured_at === leftLabel)
    const rightIndex = dataForPlot.findIndex((oneData) => oneData.measured_at === rightLabel)
    const newSelectedData = dataForPlot.slice(leftIndex,rightIndex+1)
    console.log(newSelectedData)
    setSelectedData(newSelectedData)
  }

  const resetSelectedData = () => {
    setRefAreaFrom('')
    setRefAreaTo('')
    setSelectedData([])
  }

  if (dataForPlot.length === 0) {
    return <></>
  }

  return (
    <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '95%' }}>
      <ResponsiveContainer width="100%" height={550}>
        <LineChart
          height={550}
          data={dataForPlot}
          onMouseDown={(e) => {
            if (e != null) {
              resetSelectedData()
              setRefAreaFrom(String(e.activeLabel))
            }
          }}
          onMouseMove={(e) => {
            if (e != null) {
              if (refAreaFrom && !isDataSelected) {
                setRefAreaTo(String(e.activeLabel))
              }
            }
          }}
          onMouseUp={() => {
            if (refAreaFrom === refAreaTo || refAreaTo === '') {
              resetSelectedData()
            } else {
              if (isSelectedAndLabeledOverlapping) {
                setOpenOverlappingAlert(true)
                resetSelectedData()
              } else {
                selectData()
              }
            }
          }}
        >
          <XAxis
            allowDataOverflow
            dataKey="measured_at"
            domain={['dataMin-100', 'dataMax+100']}
            type="number"
            padding={{ left: 20, right: 20 }}
            height={150}
            tickFormatter={formatEpochToDateString}
          />
          <YAxis
            orientation="left"
            allowDataOverflow
            domain={[0, sensorMaster?.tank_height ? sensorMaster.tank_height + 50 : 'dataMax+10']}
            type="number"
            padding={{ top: 10, bottom: 10 }}
            yAxisId="1"
          />
          <Tooltip
            labelFormatter={formatEpochToDatetimeString}
            position={{ y: 400 }}
          />
          <Line
            yAxisId="1"
            dataKey="not_labeled_value"
            stroke="#8884d8"
            animationDuration={1000}
            dot={false}
          />
          {labeledSensorData.length > 0 ? (
            <Line
              yAxisId="1"
              dataKey="labeled_value"
              stroke="#111111"
              animationDuration={1000}
              dot={false}
            />
          ) : null}
          {labeledSensorData.map((rangeLabeledData, index) => {
            return (
              <ReferenceArea
                key={`labeled-${index}`}
                yAxisId="1"
                x1={rangeLabeledData[0].measured_at}
                x2={rangeLabeledData[rangeLabeledData.length - 1].measured_at}
                strokeOpacity={0.3}
                label={labelDefinitions.filter(labelDef => rangeLabeledData[0].label === labelDef.label)[0].label_name}
              />
            )
          })}
          {refAreaFrom && refAreaTo ? (
            <ReferenceArea key="selected" yAxisId="1" x1={refAreaFrom} x2={refAreaTo} strokeOpacity={0.3} />
          ) : null}
          {sensorMaster?.tank_height ? (
            <ReferenceLine yAxisId="1" y={sensorMaster.tank_height} label="タンク高さ" />
          ): null}

          <Brush
            className="TimeLineChart-brush"
            dataKey="measured_at"
            stroke="#8884d8"
            travellerWidth={10}
            tickFormatter={formatEpochToDatetimeString}
          />
        </LineChart>
      </ResponsiveContainer>
      <Snackbar
        open={openOverlappingAlert}
        autoHideDuration={6000}
        onClose={handleCloseOverlappingAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseOverlappingAlert}
          severity="warning"
          sx={{ width: '100%' }}
        >
          ラベル済の範囲に重ねてラベルをつけることはできません。
        </Alert>
      </Snackbar>
    </div>
  );
}
import {
  Table, TableBody, TableRow, TableCell, TableHead, TableContainer, Typography
} from '@mui/material'
import {useAtom} from "jotai";
import {targetSensorMaster} from "../../jotai/atom";
import React from "react";

const SensorMaster = () => {
  const [sensorMaster] = useAtom(targetSensorMaster)

  if (sensorMaster == null) {
    return <></>
  }

  return (
    <>
      <TableContainer sx={{ height: 450 }}>
        <Table sx={{ padding: 1 }}>
          <TableHead>
            <TableRow key='header'>
              <TableCell>項目名</TableCell>
              <TableCell>登録データ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(sensorMaster).flatMap(([key, value]) => {
              return (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="caption" align="center" paddingTop={2}>↑スクロールして全ての情報を確認できます。</Typography>
    </>
  )
}

export default SensorMaster